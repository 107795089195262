"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSaveTable = exports.formatTableForStorage = void 0;
var table_1 = require("../components/table/table");
var table_2 = require("../components/table/table");
var globalState_1 = require("../states/globalState");
var common_selectors_1 = require("./common_selectors");
var general_1 = require("./general");
var callbacks = [];
var extractTags = function (tags) {
    return tags.split(",").map(function (tag) { return tag.trim(); }).filter(function (str) { return str !== ''; });
};
var formatTableForStorage = function () {
    var tags = extractTags(common_selectors_1.tagsInput.value);
    var newTable = {
        table: {},
        columnsCount: general_1.visibleColumns.length,
        tags: tags,
        createdAt: Date.now(),
    };
    Array.from(general_1.visibleColumns).forEach(function (column) {
        var columnName = column.dataset.name.match(/[a-z A-Z]+/)[0];
        newTable.table[(0, general_1.camelize)(columnName)] = column.innerHTML;
    });
    return newTable;
};
exports.formatTableForStorage = formatTableForStorage;
var save = function (data) {
    if (globalState_1.globalState.data.editedTableId) {
        return fetch("https://api.cbt-tables.com/tables", {
            method: "PATCH",
            headers: { "Content-Type": "application/json", Origin: general_1.originByEnv },
            body: JSON.stringify(__assign({ tableToRemoveId: globalState_1.globalState.data.editedTableId }, data)),
            credentials: "include",
        });
    }
    return fetch("https://api.cbt-tables.com/tables", {
        method: "POST",
        headers: { "Content-Type": "application/json", Origin: general_1.originByEnv },
        body: JSON.stringify(data),
        credentials: "include",
    });
};
globalState_1.globalState.registerListener({
    name: "onSave",
    listener: function () {
        if (globalState_1.globalState.isSaving()) {
            var data = (0, exports.formatTableForStorage)();
            save(data)
                .then(function () {
                callbacks.forEach(function (callback) {
                    callback();
                });
                (0, table_1.cleanTable)();
                globalState_1.globalState.data.editedTableId = null;
                (0, table_2.toggleSwitches)(document.getElementById("six-rows"));
                globalState_1.globalState.current = globalState_1.globalState.allowedStates.viewing;
            })
                .catch(function (error) {
                alert("Error: ".concat(error.message));
            });
        }
    },
});
var useSaveTable = function (newCallbacks) {
    callbacks.push.apply(callbacks, newCallbacks);
};
exports.useSaveTable = useSaveTable;
