"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createState = void 0;
// TODO try if possible to change allowed states
var createState = function (initialState, allowedStates, attributes) {
    if (initialState === void 0) { initialState = ""; }
    if (allowedStates === void 0) { allowedStates = []; }
    if (attributes === void 0) { attributes = {}; }
    var currentInternalState = initialState;
    var isValidState = function (newState) {
        return allowedStates.indexOf(newState) !== -1;
    };
    var isEqualTo = function (state) {
        if (!state) {
            throw new Error("no value for comparison was given");
        }
        return currentInternalState === state;
    };
    var state = {
        data: {},
        allowedStates: {},
        listeners: {},
        attributes: attributes,
        get current() {
            return currentInternalState;
        },
        set current(newState) {
            if (!isValidState(newState)) {
                throw new Error("tried to assign invalid state");
            }
            var previousState = currentInternalState;
            currentInternalState = newState;
            Object.values(this.listeners).forEach(function (listener) {
                listener(previousState);
            });
        },
        registerListener: function (_a) {
            var name = _a.name, listener = _a.listener;
            this.listeners[name] = listener;
        },
    };
    allowedStates.forEach(function (allowedState) {
        var key = "is".concat(allowedState.charAt(0).toUpperCase() + allowedState.slice(1));
        Object.defineProperty(state, key, {
            value: function () { return isEqualTo(allowedState); },
            writable: false,
            enumerable: false,
            configurable: false,
        });
        Object.defineProperty(state.allowedStates, allowedState, {
            value: allowedState,
            writable: false,
            enumerable: true,
            configurable: false,
        });
    });
    Object.freeze(state.allowedStates);
    return Object.freeze(state);
};
exports.createState = createState;
