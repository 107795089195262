"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cleanTable = exports.initTable = exports.tableState = exports.cleanPage = exports.toggleSwitches = exports.toggleTableState = exports.showRelevantTableSize = void 0;
var general_1 = require("../general");
var globalState_1 = require("../../states/globalState");
var tableState_1 = require("../../states/tableState");
Object.defineProperty(exports, "tableState", { enumerable: true, get: function () { return tableState_1.tableState; } });
var buttons_1 = require("./buttons");
var useSaveTable_1 = require("../useSaveTable");
var common_selectors_1 = require("../common_selectors");
var drilldown_1 = require("./drilldown");
var SELECTING_TABLE_SIZE = "selecting-table-size";
var menuOptions = document.getElementById("menu-options");
var showRelevantTableSize = function (columnsCount) {
    var firstColumnSet = false;
    Array.from(common_selectors_1.allColumns).forEach(function (column) {
        column.classList.remove("first");
        if (column.classList.contains(columnsCount)) {
            column.classList.remove("hidden");
            column.classList.add("visible");
            if (!firstColumnSet) {
                column.classList.add("first");
                firstColumnSet = true;
            }
        }
        else {
            column.classList.remove("visible");
            column.classList.add("hidden");
        }
    });
    globalState_1.globalState.current = globalState_1.globalState.allowedStates.editing;
};
exports.showRelevantTableSize = showRelevantTableSize;
var toggleTableState = function () {
    var nonHiddenColumns = Array.from(common_selectors_1.allColumns).filter(function (column) { return !column.classList.contains("hidden"); });
    var nonEmptyColumns = nonHiddenColumns.filter(function (column) { return column.textContent.length; });
    if (nonEmptyColumns.length) {
        tableState_1.tableState.current = tableState_1.tableState.allowedStates.dirty;
    }
    else {
        tableState_1.tableState.current = tableState_1.tableState.allowedStates.clean;
    }
};
exports.toggleTableState = toggleTableState;
var toggleSwitchesEvent = function (e) {
    (0, exports.toggleSwitches)(e.currentTarget);
};
var toggleSwitches = function (switchToActivate) {
    common_selectors_1.tableSwitches.forEach(function (element) {
        (0, general_1.setInactive)(element);
        element.classList.add("hidden");
    });
    switchToActivate.classList.remove("hidden");
};
exports.toggleSwitches = toggleSwitches;
var cleanPage = function () {
    common_selectors_1.allColumns.forEach(function (column) {
        column.innerHTML = "";
    });
};
exports.cleanPage = cleanPage;
var cleanTable = function () {
    (0, exports.cleanPage)();
    (0, general_1.setInactive)(common_selectors_1.newTableButton.querySelector("svg"));
    (0, general_1.setInactive)(common_selectors_1.saveButton);
    (0, general_1.setInactive)(common_selectors_1.drilldownButton);
    delete globalState_1.globalState.data.editedTableId;
    tableState_1.tableState.current = tableState_1.tableState.allowedStates.clean;
};
exports.cleanTable = cleanTable;
var onTableSwitchClick = function (event) {
    var isSelectingTableSize = menuOptions.classList.contains(SELECTING_TABLE_SIZE);
    var columnsCount = event.currentTarget.dataset.columnsCount;
    if (isSelectingTableSize) {
        menuOptions.classList.remove(SELECTING_TABLE_SIZE);
        (0, exports.showRelevantTableSize)(columnsCount);
        globalState_1.globalState.data.columnsCount = columnsCount;
        (0, exports.toggleTableState)();
        toggleSwitchesEvent(event);
    }
    else {
        menuOptions.classList.add(SELECTING_TABLE_SIZE);
        var currentColumnsCount = document.querySelector("#menu .table[data-columns-count=\"".concat(columnsCount, "\"]"));
        (0, general_1.setActive)(currentColumnsCount);
    }
};
var initTable = function () {
    (0, general_1.addEventListenerToList)(common_selectors_1.tableSwitches, "click", onTableSwitchClick);
    (0, general_1.addEventListenerToList)(common_selectors_1.allColumns, "input", exports.toggleTableState);
    common_selectors_1.newTableButton.addEventListener("click", cleanTable);
    (0, buttons_1.init)();
    globalState_1.globalState.data.columnsCount = 6;
    tableState_1.tableState.registerListener({
        name: "cleanTable",
        listener: function () {
            if (tableState_1.tableState.isDirtySinceSubmit()) {
                cleanTable();
            }
        },
    });
    (0, useSaveTable_1.useSaveTable)([
        function () {
            tableState_1.tableState.current = tableState_1.tableState.allowedStates.dirtySinceSubmit;
        },
    ]);
    (0, drilldown_1.init)();
};
exports.initTable = initTable;
