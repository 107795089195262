"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.originByEnv = exports.visibleColumns = exports.setInactive = exports.setActive = exports.addEventListenerToList = exports.camelize = void 0;
var common_selectors_1 = require("./common_selectors");
Object.defineProperty(exports, "visibleColumns", { enumerable: true, get: function () { return common_selectors_1.visibleColumns; } });
var addEventListenerToList = function (list, event, fn) {
    for (var i = 0, len = list.length; i < len; i += 1) {
        list[i].addEventListener(event, fn, false);
    }
};
exports.addEventListenerToList = addEventListenerToList;
var setActive = function (el) {
    el.classList.add('active');
    el.classList.remove('inactive');
};
exports.setActive = setActive;
var setInactive = function (el) {
    el.classList.add('inactive');
    el.classList.remove('active');
};
exports.setInactive = setInactive;
var camelize = function (str) { return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0)
        return ''; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
}); };
exports.camelize = camelize;
var originByEnv = process.env.NODE_ENV === 'production' ? 'cbt-tables.com' : 'dev.cbt-tables.com';
exports.originByEnv = originByEnv;
