"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.globalState = void 0;
var createState_1 = require("./createState");
var common_selectors_1 = require("../components/common_selectors");
exports.globalState = (0, createState_1.createState)("editing", [
    "editing",
    "saving",
    "viewing",
    "tagging",
]);
exports.globalState.registerListener({
    name: "changeBodyClass",
    listener: function () {
        common_selectors_1.body.className = common_selectors_1.body.className.replace(/(.+)(-state.*)/, "".concat(exports.globalState.current, "$2"));
    },
});
