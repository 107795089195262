"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.initLogin = exports.signOutUser = exports.onGoogleAuthorizationComplete = exports.renderGoogleLoginButton = void 0;
var general_1 = require("./general");
var loginState_1 = require("../states/loginState");
var constants_1 = require("../constants");
var useLoader_1 = require("./useLoader");
var common_selectors_1 = require("./common_selectors");
var buttons_1 = require("./table/buttons");
var profileEmail = document.querySelector(".profile-email");
var profileName = document.querySelector("#profile-name");
var drawer = document.querySelector(".side-drawer");
var loaderEl = document.querySelector(".drawer-loader");
var loader = (0, useLoader_1.useLoader)(loaderEl);
var globalLoader = (0, useLoader_1.useLoader)();
var removeSession = function () {
    return fetch("".concat(constants_1.API_PATH, "/sign-out"), {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json", Origin: general_1.originByEnv },
    });
};
loginState_1.loginState.registerListener({
    name: "resetBodyLoginState",
    listener: function () {
        var _a;
        (_a = common_selectors_1.body.classList).remove.apply(_a, Object.values(loginState_1.loginState.allowedStates));
        common_selectors_1.body.classList.add(loginState_1.loginState.current);
    },
});
loginState_1.loginState.registerListener({
    name: "checkSaveButtonState",
    listener: buttons_1.checkSaveButtonState,
});
var showLoader = function () {
    if (drawer.classList.contains("opened")) {
        loader.show();
    }
    else {
        globalLoader.show();
    }
};
var hideLoader = function () {
    loader.hide();
    globalLoader.hide();
};
var signOutUser = function (isSessionExists) {
    if (isSessionExists === void 0) { isSessionExists = true; }
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    loginState_1.loginState.current = loginState_1.loginState.allowedStates.authorizing;
                    showLoader();
                    document.getElementById("google-image").innerHTML = "";
                    profileName.innerText = "";
                    profileEmail.textContent = "";
                    if (!isSessionExists) return [3 /*break*/, 2];
                    return [4 /*yield*/, removeSession()];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    loginState_1.loginState.current = loginState_1.loginState.allowedStates.unauthorized;
                    hideLoader();
                    return [2 /*return*/];
            }
        });
    });
};
exports.signOutUser = signOutUser;
var signInUser = function (data) { return __awaiter(void 0, void 0, void 0, function () {
    var email, name, pictureUrl, imageElement;
    return __generator(this, function (_a) {
        email = data.email, name = data.name, pictureUrl = data.pictureUrl;
        imageElement = document.createElement("img");
        imageElement.src = pictureUrl;
        document.getElementById("google-image").appendChild(imageElement);
        profileName.innerText = name;
        profileEmail.textContent = email;
        loginState_1.loginState.current = loginState_1.loginState.allowedStates.authorized;
        hideLoader();
        return [2 /*return*/];
    });
}); };
var signInGoogleUser = function (googleUser) { return __awaiter(void 0, void 0, void 0, function () {
    var response, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetch("https://api.cbt-tables.com/login_google", {
                    method: "POST",
                    credentials: "include",
                    headers: { "Content-Type": "application/json", Origin: general_1.originByEnv },
                    body: JSON.stringify({
                        token: googleUser.credential,
                    }),
                })];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                if (data.isAuthorized) {
                    signInUser(data);
                    return [2 /*return*/];
                }
                signOutUser();
                hideLoader();
                alert(data.error);
                return [2 /*return*/];
        }
    });
}); };
var startSignIn = function () {
    loginState_1.loginState.current = loginState_1.loginState.allowedStates.authorizing;
    showLoader();
};
var onGoogleAuthorizationComplete = function (googleUser) {
    startSignIn();
    signInGoogleUser(googleUser);
};
exports.onGoogleAuthorizationComplete = onGoogleAuthorizationComplete;
var authenticate = function () { return __awaiter(void 0, void 0, void 0, function () {
    var response, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                startSignIn();
                return [4 /*yield*/, fetch("".concat(constants_1.API_PATH, "/authenticate"), {
                        method: "GET",
                        credentials: "include",
                        headers: { "Content-Type": "application/json", Origin: general_1.originByEnv },
                    })];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data];
        }
    });
}); };
var renderGoogleLoginButton = function () {
    if (!global.google) {
        return;
    }
    var parent = document.getElementById("google-login");
    global.google.accounts.id.renderButton(parent, {
        theme: "outline",
        type: "standard",
        shape: "pill",
        text: "continue_with",
        size: "large",
        logo_alignment: "left",
    });
};
exports.renderGoogleLoginButton = renderGoogleLoginButton;
var initGoogleLogin = function () {
    global.onload = function () {
        if (!global.google) {
            return;
        }
        global.google.accounts.id.initialize({
            client_id: "168792850063-ki6dolbpahtmvnnj6msce16125la89g1.apps.googleusercontent.com",
            callback: onGoogleAuthorizationComplete,
        });
        (0, exports.renderGoogleLoginButton)();
    };
};
var initLogin = function () { return __awaiter(void 0, void 0, void 0, function () {
    var userData;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                startSignIn();
                document
                    .getElementById("google-sign-out")
                    .addEventListener("click", function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, signOutUser()];
                            case 1:
                                _a.sent();
                                global.location.reload();
                                return [2 /*return*/];
                        }
                    });
                }); });
                initGoogleLogin();
                return [4 /*yield*/, authenticate()];
            case 1:
                userData = _a.sent();
                if (userData.isAuthorized) {
                    signInUser(userData);
                }
                else {
                    signOutUser(false);
                    global.google.accounts.id.prompt();
                }
                return [2 /*return*/];
        }
    });
}); };
exports.initLogin = initLogin;
