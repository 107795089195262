"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.initInventory = void 0;
var globalState_1 = require("../../states/globalState");
var table_1 = require("../table/table");
var tableState_1 = require("../../states/tableState");
var inventoryRow_1 = require("./inventoryRow");
var table_2 = require("../table/table");
var general_1 = require("../general");
var inventoryState_1 = require("../../states/inventoryState");
var useSaveTable_1 = require("../useSaveTable");
var useLoader_1 = require("../useLoader");
var sideDrawer_1 = require("../sideDrawer");
var common_selectors_1 = require("../common_selectors");
require("./styles.scss");
var loader = (0, useLoader_1.useLoader)();
var backToTable = document.getElementById("create-table");
var inventoryItems;
var tables = {
    temporaryTable: null,
    allTables: null,
};
var columns = {
    situation: document.querySelector('#table-columns [data-name="Situation"]'),
    emotion: document.querySelector('#table-columns [data-name="Emotion"]'),
    automaticThought: document.getElementById("automatic-thought"),
    cognitiveDistortion: document.querySelector('#table-columns [data-name="Cognitive Distortion"]'),
    rationalResponse: document.querySelector('#table-columns [data-name="Rational Response"]'),
    outcome: document.querySelector('#table-columns [data-name="Outcome"]'),
};
var parseTable = function (data, index) {
    var rowEl = document.createElement("template");
    var _a = (0, inventoryRow_1.inventoryRow)(index, data), tags = _a.tags, html = _a.html;
    rowEl.innerHTML = html;
    return {
        newRow: rowEl.content,
        tags: tags,
    };
};
var fetchUserTables = function () {
    return fetch("https://api.cbt-tables.com/tables", {
        headers: { Origin: general_1.originByEnv, "Content-Type": "application/json" },
        credentials: "include",
    });
};
var adjustTagsToTables = function () {
    var tables = common_selectors_1.inventorySection.querySelectorAll(".inventory-item");
    var allTags = Array.from(tables).reduce(function (acc, table) {
        var tags = table.dataset.tags.split(",");
        return __spreadArray(__spreadArray([], acc, true), tags, true);
    }, []);
    var tags = Array.from(common_selectors_1.tagsSection.querySelectorAll(".container-tag"));
    tags.forEach(function (tag) {
        if (!allTags.includes(tag.textContent)) {
            tag.remove();
        }
    });
};
var deleteTable = function (event) {
    event.stopPropagation();
    var createdAt = event.target.closest("button").id;
    fetch("https://api.cbt-tables.com/tables", {
        method: "DELETE",
        body: JSON.stringify({ createdAt: createdAt }),
        headers: {
            Origin: general_1.originByEnv,
            "Content-Type": "application/json",
        },
        credentials: "include",
    });
    var inventoryItem = event.target.closest(".inventory-item");
    inventoryItem.remove();
    adjustTagsToTables();
};
var populateTable = function (tableToShow) {
    Object.keys(columns).forEach(function (columnName) {
        if (tableToShow[columnName]) {
            columns[columnName].innerHTML = tableToShow[columnName];
        }
    });
};
var prepareTagsInput = function (tags) {
    common_selectors_1.tagsInput.value = tags.join(", ");
};
var onClickShowExistingTable = function (event) {
    if (tableState_1.tableState.isDirty()) {
        tables.temporaryTable = JSON.stringify((0, useSaveTable_1.formatTableForStorage)());
    }
    var clickedTable = tables.allTables.find(function (table) { return table.createdAt === Number(event.target.closest("li").id); });
    inventoryState_1.inventoryState.data.lastClickedItemId = clickedTable.createdAt; // used for scrolling into view last selected table
    (0, table_1.cleanPage)();
    populateTable(clickedTable.table);
    prepareTagsInput(clickedTable.tags);
    globalState_1.globalState.current = globalState_1.globalState.allowedStates.editing;
    (0, table_2.showRelevantTableSize)(clickedTable.columnsCount);
    (0, table_2.toggleTableState)();
    var switchToActivate = document.querySelector("[data-columns-count=\"".concat(clickedTable.columnsCount, "\"]"));
    (0, table_2.toggleSwitches)(switchToActivate);
    globalState_1.globalState.current = globalState_1.globalState.allowedStates.editing;
    globalState_1.globalState.data.editedTableId = clickedTable.createdAt; // used for updating a table instead of saving a new one
};
var loadData = function () { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchUserTables()];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.json()];
        }
    });
}); };
var showAllTags = function () {
    inventoryItems.forEach(function (table) { return table.classList.remove("hidden"); });
};
var filterByTags = function () {
    var selectedTags = Array.from(document.querySelectorAll(".container-tag.active")).map(function (tag) { return tag.textContent; });
    if (selectedTags.length === 0) {
        showAllTags();
        return;
    }
    inventoryItems.forEach(function (table) {
        var tableTags = table.dataset.tags.split(",");
        if (selectedTags.every(function (tag) { return tableTags.includes(tag); })) {
            table.classList.remove("hidden");
        }
        else {
            table.classList.add("hidden");
        }
    });
};
var toogleTag = function (event) {
    var tag = event.target;
    if (event.target.classList.contains("active")) {
        (0, general_1.setInactive)(tag);
    }
    else {
        (0, general_1.setActive)(tag);
    }
};
var setupTagsContainer = function (allTags) {
    if (!allTags) {
        return;
    }
    allTags.forEach(function (tag) {
        var tagEl = document.createElement("template");
        tagEl.innerHTML = "<div class=\"container-tag\" data-tagname=\"".concat(tag, "\">").concat(tag, "</div>");
        common_selectors_1.tagsSection.appendChild(tagEl.content);
    });
    var tagButtons = common_selectors_1.tagsSection.querySelectorAll(".container-tag");
    (0, general_1.addEventListenerToList)(tagButtons, "click", toogleTag);
    (0, general_1.addEventListenerToList)(tagButtons, "click", filterByTags);
};
var prepareTables = function (items) {
    common_selectors_1.inventorySection.innerHTML = "";
    common_selectors_1.tagsSection.innerHTML = "";
    var allTags = [];
    tables.allTables = items;
    items.forEach(function (table, index) {
        var _a = parseTable(table, index), newRow = _a.newRow, tags = _a.tags;
        common_selectors_1.inventorySection.appendChild(newRow);
        if (!tags) {
            return;
        }
        tags.forEach(function (tag) {
            if (!allTags.includes(tag)) {
                allTags.push(tag);
            }
        });
    });
    setupTagsContainer(allTags);
    finishPreparingTables();
};
var finishPreparingTables = function () {
    var deleteButtons = document.querySelectorAll(".trash");
    inventoryItems = document.querySelectorAll("li.inventory-item");
    (0, general_1.addEventListenerToList)(deleteButtons, "click", deleteTable);
    (0, general_1.addEventListenerToList)(inventoryItems, "click", onClickShowExistingTable);
    inventoryState_1.inventoryState.current = inventoryState_1.inventoryState.allowedStates.uptodate;
    loader.hide();
};
var scrollToLastClickedItem = function () {
    if (!inventoryState_1.inventoryState.lastClickedItemId) {
        return;
    }
    var lastClickedTable = document.getElementById(inventoryState_1.inventoryState.lastClickedItemId);
    lastClickedTable.scrollIntoView();
};
var initInventory = function () {
    inventoryState_1.inventoryState.registerListener({
        name: "tagsContainer",
        description: "hide tags container when inventory is not up to date",
        listener: function () {
            if (inventoryState_1.inventoryState.isOutdated()) {
                common_selectors_1.tagsSection.classList.add("hidden");
            }
            else {
                common_selectors_1.tagsSection.classList.remove("hidden");
            }
        },
    });
    globalState_1.globalState.registerListener({
        name: "initInventory",
        listener: function () { return __awaiter(void 0, void 0, void 0, function () {
            var items;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!globalState_1.globalState.isViewing()) return [3 /*break*/, 2];
                        if (!inventoryState_1.inventoryState.isOutdated()) return [3 /*break*/, 2];
                        loader.show();
                        return [4 /*yield*/, loadData()];
                    case 1:
                        items = (_a.sent()).Items;
                        prepareTables(items);
                        loader.hide();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); },
    });
    common_selectors_1.inventoryButton.addEventListener("click", function () {
        globalState_1.globalState.current = globalState_1.globalState.allowedStates.viewing;
        scrollToLastClickedItem();
        (0, sideDrawer_1.closeSideDrawer)();
    });
    backToTable.addEventListener("click", function () {
        globalState_1.globalState.current = globalState_1.globalState.allowedStates.editing;
        (0, table_2.showRelevantTableSize)(globalState_1.globalState.data.columnsCount);
        (0, table_2.toggleTableState)();
        var switchToActivate = document.querySelector("[data-columns-count=\"".concat(globalState_1.globalState.data.columnsCount, "\"]"));
        (0, table_2.toggleSwitches)(switchToActivate);
        (0, table_1.cleanTable)();
    });
    (0, useSaveTable_1.useSaveTable)([
        function () {
            inventoryState_1.inventoryState.current = inventoryState_1.inventoryState.allowedStates.outdated;
        },
    ]);
};
exports.initInventory = initInventory;
