"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initDarkMode = void 0;
var darkModeState_1 = require("../../states/darkModeState");
var login_1 = require("../login");
var html = document.querySelector('html');
var slider = document.getElementById('slider-container');
var darkModeAuto = document.getElementById('dark-mode-auto');
var darkModeOn = document.getElementById('dark-mode-on');
var darkModeOff = document.getElementById('dark-mode-off');
var initDarkModeMenu = function () {
    var drawer = document.getElementById('dark-mode-drawer');
    drawer.closest('.drawer-button').addEventListener('click', function () {
        if (slider.classList.contains('slider-active')) {
            slider.classList.remove('slider-active');
        }
        else {
            slider.classList.add('slider-active');
        }
    });
    darkModeAuto.addEventListener('click', function (e) {
        e.stopPropagation();
        darkModeState_1.darkModeState.current = 'auto';
        darkModeAuto.classList.add('active');
        darkModeOn.classList.remove('active');
        darkModeOff.classList.remove('active');
    });
    darkModeOn.addEventListener('click', function (e) {
        e.stopPropagation();
        darkModeState_1.darkModeState.current = 'on';
        darkModeOn.classList.add('active');
        darkModeAuto.classList.remove('active');
        darkModeOff.classList.remove('active');
    });
    darkModeOff.addEventListener('click', function (e) {
        e.stopPropagation();
        darkModeState_1.darkModeState.current = 'off';
        darkModeOff.classList.add('active');
        darkModeAuto.classList.remove('active');
        darkModeOn.classList.remove('active');
    });
};
var initDarkMode = function () {
    var _a;
    initDarkModeMenu();
    darkModeState_1.darkModeState.registerListener({
        name: 'changeDarkMode',
        listener: function () {
            darkModeState_1.darkModeState.attributes.intervalRef = global.clearTimeout(darkModeState_1.darkModeState.attributes.intervalRef);
            localStorage.setItem('darkMode', darkModeState_1.darkModeState.current);
            if (darkModeState_1.darkModeState.current === 'auto') {
                darkModeState_1.darkModeState.attributes.intervalRef = global.setTimeout(function () {
                    darkModeState_1.darkModeState.current = 'auto';
                }, 60000);
                var isNight = global.matchMedia('(prefers-color-scheme: dark)');
                if (isNight.matches) {
                    html.dataset.darkMode = 'on';
                }
                else {
                    html.dataset.darkMode = 'off';
                }
                return;
            }
            html.dataset.darkMode = darkModeState_1.darkModeState.current;
            (0, login_1.renderGoogleLoginButton)();
        },
    });
    darkModeState_1.darkModeState.current = darkModeState_1.currnetModeSetting;
    (_a = document.getElementById("dark-mode-".concat(darkModeState_1.darkModeState.current))) === null || _a === void 0 ? void 0 : _a.classList.add('active');
};
exports.initDarkMode = initDarkMode;
