"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.inventoryRow = void 0;
var getRowText = function (data) {
    var _a = data.table, automaticThought = _a.automaticThought, situation = _a.situation;
    if (situation === null || situation === void 0 ? void 0 : situation.length)
        return situation;
    if (automaticThought === null || automaticThought === void 0 ? void 0 : automaticThought.length)
        return automaticThought;
    return Object.values(data.table).find(function (columnText) {
        if (columnText.length)
            return columnText;
    });
};
var inventoryRow = function (index, data) {
    var rowHtml = getRowText(data);
    var text = new DOMParser().parseFromString(rowHtml, 'text/html').body.textContent.replace(/⬇/g, '&nbsp;&nbsp;&nbsp;⬇&nbsp;&nbsp;&nbsp;');
    var html = "\n  <li class='inventory-item' tabindex='".concat(index, "' id=").concat(data.createdAt, " data-tags=\"").concat(data.tags.join(','), "\">\n    <div class='left'>\n      <div class='content'>").concat(text, "</div>\n      <div class='tag'>").concat(data.tags.join(', '), "</div>\n    </div>\n\n    <div class='icon-container'>\n      <div class='columns-count'>").concat(data.columnsCount, "</div>\n      <button class='trash' id='").concat(data.createdAt, "'>\n        <svg class='trashSVG' xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 16 18\"><path d=\"M1.18 6.4l.84 10.16c.08.82.76 1.44 1.58 1.44h8.62c.82 0 1.5-.62 1.58-1.44l.84-10.16H1.18zm4.1 7.9a.53.53 0 11-1.06 0V7.99a.53.53 0 111.05 0v6.33zm3.16 0a.53.53 0 11-1.06 0V7.99a.53.53 0 111.06 0v6.33zm3.16 0a.53.53 0 11-1.05 0V7.99a.53.53 0 111.05 0v6.33zm2.64-12.19h-3.7v-.53A1.56 1.56 0 008.98 0H6.84c-.87 0-1.58.71-1.58 1.58v.53H1.58A1.58 1.58 0 000 3.69v1.06c0 .29.24.52.53.52h14.76a.53.53 0 00.53-.52V3.69c0-.87-.71-1.58-1.58-1.58zm-4.75 0H6.33v-.53c0-.29.24-.53.53-.53h2.1c.3 0 .53.24.53.53v.53z\"/></svg>\n      </button>\n    </div>\n  </li>\n  ");
    return {
        html: html,
        tags: data.tags,
    };
};
exports.inventoryRow = inventoryRow;
