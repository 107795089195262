"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initSideDrawer = exports.toggleSideDrawer = exports.openSideDrawer = exports.closeSideDrawer = void 0;
var general_1 = require("./general");
var globalState_1 = require("../states/globalState");
var common_selectors_1 = require("./common_selectors");
var drawer = document.querySelector(".side-drawer");
var mask = document.querySelector(".mask");
var closeSideDrawer = function () {
    drawer.classList.remove("opened");
    mask.classList.remove("opened");
};
exports.closeSideDrawer = closeSideDrawer;
var openSideDrawer = function () {
    mask.classList.add("opened");
    drawer.classList.add("opened");
};
exports.openSideDrawer = openSideDrawer;
var toggleSideDrawer = function () {
    if (drawer.classList.contains("opened")) {
        return (0, exports.closeSideDrawer)();
    }
    return (0, exports.openSideDrawer)();
};
exports.toggleSideDrawer = toggleSideDrawer;
var initOpenMenuOnESC = function () {
    document.onkeydown = function (event) {
        var isEscape = false;
        if ("key" in event) {
            isEscape = event.key === "Escape" || event.key === "Esc";
        }
        else {
            isEscape = event["keyCode"] === 27;
        }
        if (isEscape) {
            if (globalState_1.globalState.current === globalState_1.globalState.allowedStates.tagging) {
                globalState_1.globalState.current = globalState_1.globalState.allowedStates.editing;
                return;
            }
            (0, exports.toggleSideDrawer)();
        }
    };
};
var initSideDrawer = function () {
    (0, general_1.addEventListenerToList)([common_selectors_1.hamburgerMenu], "click", exports.openSideDrawer);
    (0, general_1.addEventListenerToList)([mask], "click", exports.closeSideDrawer);
    initOpenMenuOnESC();
};
exports.initSideDrawer = initSideDrawer;
