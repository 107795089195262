"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var globalState_1 = require("../states/globalState");
var useLoader_1 = require("../components/useLoader");
var init = function () {
    var saveTagButton = document.getElementById("save-tag-name");
    var cancelTagButton = document.getElementById("cancel-tag-name");
    cancelTagButton.addEventListener("click", function () {
        globalState_1.globalState.current = globalState_1.globalState.allowedStates.editing;
    });
    var loader = (0, useLoader_1.useLoader)();
    saveTagButton.addEventListener("click", function () {
        globalState_1.globalState.current = globalState_1.globalState.allowedStates.saving;
        loader.show();
    });
};
exports.default = init;
